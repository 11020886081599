body {
  font-family: 'Nunito', sans-serif;
  height: 100vh;
  background-color: #e4e4e4;
}

html.wf-loading * {
  opacity: 0;
}

@media (min-width: 767px) {
  h1 {
    margin-top: 3em;
  }
}

.section {
  background: linear-gradient(#ffffff, #e4e4e4);
}

.button {
  margin: 0.5em;
}

.footer {
  color: #999;
  background-color: transparent;
}